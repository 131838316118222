<script setup lang="ts">
import { defineAsyncComponent } from '@@/bits/vue'
import type { QueuedGlobalSnackbarNotification } from '@@/pinia/global_snackbar'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { storeToRefs } from 'pinia'

const OzBaseSnackbar = defineAsyncComponent(() => import('@@/library/v4/components/OzBaseSnackbar.vue'))

const props = withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    topOffset?: number
  }>(),
  { darkMode: 'auto', topOffset: 0 },
)

const globalSnackbarStore = useGlobalSnackbarStore()
const { snackbarArray } = storeToRefs(globalSnackbarStore)
const { executeActions, removeSnackbar } = globalSnackbarStore

function calculateSnackbarPosition(snackbarIndex: number): string {
  const actualIndex =
    snackbarIndex -
    Math.min(...snackbarArray.value.map((snackbar: QueuedGlobalSnackbarNotification) => snackbar.originalIndex))
  // 49 is the height of snackbar plus margin between snackbars
  // each subsequent snackbar is height plus margin of a snackbar from the top of the viewport plus initial gap (16px)
  return `${(snackbarArray.value.length - actualIndex) * 49 - 16 + props.topOffset}px`
}

function onClick(snackbar: QueuedGlobalSnackbarNotification): void {
  if (!snackbar.dismissable) {
    return
  }
  removeSnackbar(snackbar.uid)
}
</script>

<template>
  <div
    :class="['fixed w-full flex flex-col items-center pointer-events-none start-0 isolate z-global-snackbar']"
    role="status"
  >
    <transition-group name="snackbar-transition">
      <OzBaseSnackbar
        v-for="(snackbar, i) in snackbarArray"
        :key="snackbar.uid"
        :data-testid="`snackbar${i}`"
        :dark-mode="darkMode"
        class="overflow-visible fixed -translate-s-1/2 transition-all"
        :notification-type="snackbar.notificationType"
        :message="snackbar.message"
        :icon-name="snackbar.iconName"
        :uid="snackbar.uid"
        :action-text="snackbar.actionText"
        :style="{ top: calculateSnackbarPosition(snackbar.originalIndex) }"
        :should-truncate="snackbar.shouldTruncate"
        @action-text-click="executeActions(snackbar.actionTextActions, $event)"
        @click="onClick(snackbar)"
      />
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
// Animation functions follows https://material.io/design/motion/speed.html#easing
.snackbar-transition-enter-active {
  /* Ease in for entering */
  animation: fadeInTop 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.snackbar-transition-leave-active,
.snackbar-transition-leave-to {
  /* Ease out for leaving */
  animation: fadeOutTop 0.3s cubic-bezier(0.4, 0, 1, 1);
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
